<template>
  <div>
    <NavTitle class="mb16" title="商户进驻" :showBack="false"></NavTitle>
    <div class="ml20 mr20">
      <el-menu  :default-active="listType" mode="horizontal" active-text-color="#08C8BD"
      @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in typeList" :key="index">{{item.text}}</el-menu-item>
      </el-menu>
    </div>
    <div class="pl20 pr20 mt16">
      <el-table
        :data="intentionList"
        style="width: 100%">
        <el-table-column
          prop="realName"
          label="客户姓名"
          width="100">
        </el-table-column>
        <el-table-column
          prop="postName"
          label="招聘岗位">
        </el-table-column>
        <el-table-column
          prop="hc"
          label="招聘人数"
          width="100">
        </el-table-column>
        <el-table-column
          prop="contact"
          label="客户联系方式"
          width="150">
        </el-table-column>
        <el-table-column
          prop="entName"
          label="企业/商户名称">
        </el-table-column>
        <el-table-column
          prop="budget"
          label="招聘广告投放预算(元/月)"
          width="150">
        </el-table-column>
        <el-table-column
          prop="budget"
          label="岗位类型">
          <template slot-scope="scope">
            <span>{{getIntentionPost(scope.row.postTypes)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="提交时间">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态">
          <template slot-scope="scope">
            <span>{{statusTypes[scope.row.status] || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="notes"
          label="备注">
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div v-auth="'MODIFY_INTENTIONS'">
              <span class="pointer theme-text" @click="$refs.notePopupRef.open({detail: scope.row})">备注</span>
              <span class="gap-line">|</span>
              <el-dropdown @command="editStatus($event, scope.row)">
                <span class="el-dropdown-link">
                  <span class="pointer theme-text">更多<i class="el-icon-arrow-down el-icon--right"></i></span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="status-blue" :command="1" v-if="scope.row.status != 1">转为洽谈中</el-dropdown-item>
                  <el-dropdown-item class="red-text" :command="3" v-if="scope.row.status != 3">转为合作失败</el-dropdown-item>
                  <el-dropdown-item class="status-success" :command="2" v-if="scope.row.status != 2">转为合作成功</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt24" v-if="intentionList.length > 0">
        <Pagination :current-page="page.current + 1" @next-click="nextPage" @prev-click="prevPage"/>
      </div>
    </div>

    <!-- 添加备注 -->
    <NotePopup ref="notePopupRef" @update="getIntentionList"></NotePopup>
  </div>
</template>

<script>
import { intentionMangeApi } from '@/api/admin.js'
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import NotePopup from './components/NotePopup.vue'
export default {
  name: 'Collect',
  components: {
    NavTitle,
    Pagination,
    NotePopup
  },
  data() {
    return {
      listType: '0',
      typeList: [
        {text:'未处理', type: '0'},
        {text:'洽谈中', type: '1'},
        {text:'合作成功', type: '2'},
        {text:'合作失败', type: '3'},
      ],
      // 0-未沟通 1-洽谈中 2-合作成功 3-合作失败
      statusTypes: {
        0: '未沟通',
        1:'洽谈中',
        2:'合作成功',
        3:'合作失败',
      },
      page: {
        current: 0,
        size: 10
      },
      intentionList: []
    }
  },
  created() {
    this.getIntentionList()
  },
  methods: {
    menuSelect(val) {
      this.listType = val
      this.page.current = 0
      this.getIntentionList()
    },
    getIntentionList() {
      const formData = {
        act: 0,
        listType: Number(this.listType),
        startPage: this.page.current
      }
      console.log('formData', formData)
      intentionMangeApi(formData).then(res => {
        console.log('意向收集列表', res)
        const list = res.data.intentionListResult?.intentionList || []
        if(list.length != 0 || this.page.current == 0) {
          this.intentionList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.page.current--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    getIntentionPost(types) {
      if(!types) return ''
      const typeMap = {
        1: '本城市线下岗位',
        2: '全国线下岗位',
        3: '全国线上岗位',
        4: '其他招聘',
      }
      let result = ''
      for (const type of types) {
        if(typeMap[type]) result += typeMap[type] + '，'
      }
      return result
    },
    editStatus(e, info) {
      console.log(e, info)
      const formData = {
        act: 1,
        uid: info.uid,
        status: e
      }
      console.log('formData', formData)
      intentionMangeApi(formData).then(res => {
        this.$tips({message:res.msg, type:'success'})
        this.getIntentionList()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    nextPage() {
      this.page.current++
      this.getIntentionList()
    },
    prevPage() {
      this.page.current = this.page.current > 0 ? this.page.current - 1 : 0
      this.getIntentionList()
    },
  }
}
</script>

<style lang="scss" scoped>
.oprate-row {
  span {
    cursor: pointer;
  }
}
.status-blue {
  color: #199EFF;
}
.status-success {
  color: #04D267;
}
</style>